import type { NotificationAction } from '#ui/types';
import { Query, type QueryCacheNotifyEvent, useQueryClient } from '@tanstack/vue-query';
import { useWebSocket } from '@vueuse/core';
import lodashKeyBy from 'lodash-es/keyBy';

import {
    type AuctionDetailSnapshotWsEventResponseDto,
    type AuctionItemEntity,
    type AuctionListingSnapshotWsEventResponseDto,
    type AuctionsListingDto,
    AuctionsWsInEventEnum,
    AuctionsWsOutEvent,
    type AuctionWonWsEventResponseDto,
    type AuctionWsEventDtoEvents,
    type AuthorizeWsEventBodyDto,
    type ObserveAuctionDetailWsBodyDto,
    type ObserveAuctionListingWsBodyDto,
    type UserAutoBidMaxThresholdReachedWsEventResponseDto,
    type UserBailAuthorizedWsEventResponseDto,
    type UserMaxBidderRestoredWsEventResponseDto,
    type UserMetaSnapshotWsEventResponseDto,
    type UserOutBidWsEventResponseDto,
    type UserWinningBidWsEventResponseDto,
} from '~/apiClient';
import { AUCTION_DETAIL_QUERY_KEY_PREFIX, AUCTION_LISTING_QUERY_KEY_PREFIX, USER_BIDS_QUERY_KEY_PREFIX } from '~/constants/queryKeyPrefix';
import { NotificationTimeout } from '~/types/notifications.type';

type WsMessage = AuctionWsEventDtoEvents | UserMetaSnapshotWsEventResponseDto;

enum WsEntityType {
    Listing,
    Detail,
}

type WsEntityManagerReturn = {
    sendObserveMessage: (options?: { subscribeOnly?: boolean }) => void;
    handleSnapshotMessage: (message: WsMessage) => boolean;
};

type MakeWsEntityManager = (entityType: WsEntityType) => WsEntityManagerReturn;

const isAuctionListingSnapshot = (message: WsMessage): message is AuctionListingSnapshotWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.AuctionListingSnapshot;

const isAuctionDetailSnapshot = (message: WsMessage): message is AuctionDetailSnapshotWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.AuctionDetailSnapshot;

const isUserMetaSnapshot = (message: WsMessage): message is UserMetaSnapshotWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.UserMetaSnapshot;

const isUserOutBid = (message: WsMessage): message is UserOutBidWsEventResponseDto => message.event === AuctionsWsOutEvent.UserOutBid;

const isUserBailAuthorized = (message: WsMessage): message is UserBailAuthorizedWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.UserBailAuthorized;

const isUserMaxBidderRestored = (message: WsMessage): message is UserMaxBidderRestoredWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.UserMaxBidderRestored;

const isUserOffersUpdate = (message: WsMessage): message is UserMaxBidderRestoredWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.UserOffersUpdate;

const isAuctionWon = (message: WsMessage): message is AuctionWonWsEventResponseDto => message.event === AuctionsWsOutEvent.AuctionWon;

const isUserWinningBid = (message: WsMessage): message is UserWinningBidWsEventResponseDto => message.event === AuctionsWsOutEvent.UserWinningBid;

const isUserAutoBidMaxThresholdReached = (message: WsMessage): message is UserAutoBidMaxThresholdReachedWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.UserAutoBidMaxThresholdReached;

export default defineNuxtPlugin({
    name: 'wsAuctions',
    dependsOn: ['vueQuery'],

    setup(nuxtApp) {
        const runtimeConfig = useRuntimeConfig();
        const { webSocket: webSocketConfig } = useAppConfig();
        const {
            isLoggedIn,
            state: { rawToken },
        } = useAuthUtils();
        const queryClient = useQueryClient();
        const queryCache = queryClient.getQueryCache();
        const safeJsonParse = useSafeJsonParse();
        const localePath = useLocalePath();
        const { notifyWarning, notifySuccess, notifyInfo } = useNotification();
        const route = useRoute();
        const getRouteBaseName = useRouteBaseName();
        const { tt, tn } = useTypedI18n();
        const { logError } = useLogs();

        const wsEndpoint = `${runtimeConfig.public.wsBaseUrl}/auctions`;

        const messageFormatter = {
            ping: (): string => JSON.stringify({ event: AuctionsWsInEventEnum.Ping }),

            authorize: (token: string): string =>
                JSON.stringify({ event: AuctionsWsInEventEnum.Authorize, data: { token } satisfies AuthorizeWsEventBodyDto }),

            unauthorize: (): string => JSON.stringify({ event: AuctionsWsInEventEnum.Unauthorize }),

            observeListing: (ids: string[]): string =>
                JSON.stringify({
                    event: AuctionsWsInEventEnum.ObserveAuctionListing,
                    data: { auctions: ids } satisfies ObserveAuctionListingWsBodyDto,
                }),

            observeDetail: (id: string | null): string =>
                JSON.stringify({
                    event: AuctionsWsInEventEnum.ObserveAuctionDetail,
                    data: { auctionId: id } satisfies ObserveAuctionDetailWsBodyDto,
                }),
        };

        const isAuctionPage = (message: { data: { slug: string } }): boolean =>
            getRouteBaseName(route) === 'auction-slug' && route.params.slug === message.data.slug;

        const formatNotifySubject = (
            message: { data: { slug: string; brand: string; model: string; version: string } },
            inAuctionTranslation: TranslationKey,
            linkedAuctionTranslation: TranslationKey
        ): string =>
            isAuctionPage(message)
                ? tt(inAuctionTranslation)
                : tt(linkedAuctionTranslation, {
                      brand: message.data.brand,
                      model: message.data.model,
                      version: message.data.version,
                  });

        const formatAuctionLinkNotifyActions = (message: { data: { slug: string } }): NotificationAction[] | undefined =>
            isAuctionPage(message)
                ? undefined
                : [
                      {
                          label: tt('notifications.goToAuction'),
                          click: (): void => {
                              navigateTo(localePath({ name: 'auction-slug', params: { slug: message.data.slug } }));
                          },
                      },
                  ];

        const makeWsEntityManager: MakeWsEntityManager = entityType => {
            const queryKeyPrefix = entityType === WsEntityType.Listing ? AUCTION_LISTING_QUERY_KEY_PREFIX : AUCTION_DETAIL_QUERY_KEY_PREFIX;
            const observedIds = ref<string[]>([]);

            const sendObserveMessage: WsEntityManagerReturn['sendObserveMessage'] = ({ subscribeOnly } = {}) => {
                (!subscribeOnly || observedIds.value.length) &&
                    send(
                        entityType === WsEntityType.Listing
                            ? messageFormatter.observeListing(observedIds.value)
                            : messageFormatter.observeDetail(observedIds.value[0] ?? null)
                    );
            };

            const handleSnapshotMessage = (message: WsMessage): boolean => {
                if (entityType === WsEntityType.Listing && isAuctionListingSnapshot(message)) {
                    const { data: snapshotData } = message;
                    const snapshotDataMap = lodashKeyBy(snapshotData, 'id');

                    const cache = getCache<AuctionsListingDto>({ active: true });
                    const cacheData = cache?.state.data;

                    if (cacheData) {
                        cache.setData({
                            ...cacheData,
                            auctions: cacheData.auctions.map(auction => ({ ...auction, ...snapshotDataMap[auction.id] })),
                        });
                    }

                    return true;
                }

                if (entityType === WsEntityType.Detail && (isAuctionDetailSnapshot(message) || isUserMetaSnapshot(message))) {
                    const { data: snapshotData } = message;

                    const cache = getCache<AuctionItemEntity>({ active: true });
                    const cacheData = cache?.state.data;

                    if (cacheData) {
                        cache.setData({ ...cacheData, ...snapshotData });
                    }

                    return true;
                }

                return false;
            };

            const getCache = <T>(options: { active?: boolean } = {}): Query<T> | undefined => {
                const caches = queryCache.findAll({
                    queryKey: [queryKeyPrefix],
                    ...(options.active && { type: 'active' }),
                });

                return caches[0] as Query<T> | undefined;
            };

            const isSetCacheEvent = (event: QueryCacheNotifyEvent): boolean =>
                event.type === 'updated' && event.query.queryKey[0] === queryKeyPrefix && event.action.type === 'success';

            const isUnsetCacheEvent = (event: QueryCacheNotifyEvent): boolean =>
                event.type === 'observerRemoved' && event.query.queryKey[0] === queryKeyPrefix && event.query.observers.length === 0;

            const handleCacheEvent = (event: QueryCacheNotifyEvent): void => {
                if (isSetCacheEvent(event)) {
                    if (entityType === WsEntityType.Listing) {
                        const data = event.query.state.data as AuctionsListingDto;
                        observedIds.value = data?.auctions?.map(auction => auction.id);
                    } else {
                        const data = event.query.state.data as AuctionItemEntity;
                        observedIds.value = [data.id];
                    }
                } else if (isUnsetCacheEvent(event)) {
                    observedIds.value = [];
                }
            };

            const initObserved = (): void => {
                const cache = getCache();
                const cacheData = cache?.state.data;

                if (cacheData) {
                    if (entityType === WsEntityType.Listing) {
                        observedIds.value = (cacheData as AuctionsListingDto).auctions.map(auction => auction.id);
                    } else {
                        observedIds.value = [(cacheData as AuctionItemEntity).id];
                    }
                }
            };

            watch(observedIds, (value, oldValue) => {
                if (value.join() === oldValue.join() || status.value !== 'OPEN') return;
                sendObserveMessage();
            });

            queryCache.subscribe(handleCacheEvent);

            nuxtApp.hooks.hook('app:created', () => {
                initObserved();
            });

            return { sendObserveMessage, handleSnapshotMessage };
        };

        const handleUserOutBidMessage = (message: WsMessage): boolean => {
            if (isUserOutBid(message)) {
                notifyWarning({
                    title: tt(message.data.isAutoBidTie ? 'notifications.userOutBid.titleTie' : 'notifications.userOutBid.title'),
                    description: tt(message.data.isAutoBidTie ? 'notifications.userOutBid.descriptionTie' : 'notifications.userOutBid.description', {
                        subject: formatNotifySubject(message, 'notifications.userOutBid.inAuction', 'notifications.userOutBid.linkedAuction'),
                        prevBidAmount: tn(message.data.prevBidAmount, 'currency'),
                        currentBidAmount: tn(message.data.currentBidAmount, 'currency'),
                    }),
                    timeout: NotificationTimeout.NoTimeout,
                    actions: formatAuctionLinkNotifyActions(message),
                });

                return true;
            }

            return false;
        };

        const handleUserBailAuthorizedMessage = (message: WsMessage): boolean => {
            if (isUserBailAuthorized(message)) {
                notifySuccess({
                    title: tt('notifications.userBailAuthorized.title'),
                    description: tt('notifications.userBailAuthorized.description', {
                        subject: formatNotifySubject(
                            message,
                            'notifications.userBailAuthorized.inAuction',
                            'notifications.userBailAuthorized.linkedAuction'
                        ),
                    }),
                    timeout: NotificationTimeout.NoTimeout,
                    actions: formatAuctionLinkNotifyActions(message),
                });

                return true;
            }

            return false;
        };

        const handleUserMaxBidderRestoredMessage = (message: WsMessage): boolean => {
            if (isUserMaxBidderRestored(message)) {
                notifySuccess({
                    title: tt('notifications.userMaxBidderRestored.title'),
                    description: tt('notifications.userMaxBidderRestored.description', {
                        subject: formatNotifySubject(
                            message,
                            'notifications.userMaxBidderRestored.inAuction',
                            'notifications.userMaxBidderRestored.linkedAuction'
                        ),
                        prevBidAmount: tn(message.data.prevBidAmount, 'currency'),
                        currentBidAmount: tn(message.data.currentBidAmount, 'currency'),
                    }),
                    timeout: NotificationTimeout.NoTimeout,
                    actions: formatAuctionLinkNotifyActions(message),
                });

                return true;
            }

            return false;
        };

        const handleUserOffersUpdate = (message: WsMessage): boolean => {
            if (isUserOffersUpdate(message) && getRouteBaseName(route) === 'account-bids') {
                queryClient.invalidateQueries({ queryKey: [USER_BIDS_QUERY_KEY_PREFIX] });

                return true;
            }

            return false;
        };

        const handleAuctionWonMessage = (message: WsMessage): boolean => {
            if (isAuctionWon(message)) {
                notifySuccess({
                    title: tt('notifications.auctionWon.title'),
                    description: tt('notifications.auctionWon.description', {
                        subject: formatNotifySubject(message, 'notifications.auctionWon.inAuction', 'notifications.auctionWon.linkedAuction'),
                        currentPrice: tn(message.data.currentPrice, 'currency'),
                        ...(!message.data.reservePriceReached && { reserve: tt('notifications.auctionWon.reservePriceNotReached') }),
                    }),
                    timeout: NotificationTimeout.NoTimeout,
                    actions: formatAuctionLinkNotifyActions(message),
                });

                return true;
            }

            return false;
        };

        const handleUserWinningBidMessage = (message: WsMessage): boolean => {
            if (isUserWinningBid(message)) {
                notifySuccess({
                    title: tt('notifications.userWinningBid.title'),
                    description: tt('notifications.userWinningBid.description', {
                        subject: formatNotifySubject(message, 'notifications.userWinningBid.inAuction', 'notifications.userWinningBid.linkedAuction'),
                        amount: tn(message.data.currentBidAmount, 'currency'),
                    }),
                    timeout: NotificationTimeout.NoTimeout,
                    actions: formatAuctionLinkNotifyActions(message),
                });

                return true;
            }

            return false;
        };

        const handleUserAutoBidMaxThresholdReachedMessage = (message: WsMessage): boolean => {
            if (isUserAutoBidMaxThresholdReached(message)) {
                notifyInfo({
                    title: tt('notifications.userAutoBidMaxThresholdReached.title'),
                    description: tt('notifications.userAutoBidMaxThresholdReached.description', {
                        subject: formatNotifySubject(
                            message,
                            'notifications.userAutoBidMaxThresholdReached.inAuction',
                            'notifications.userAutoBidMaxThresholdReached.linkedAuction'
                        ),
                        amount: tn(message.data.maximumAmount, 'currency'),
                    }),
                    timeout: NotificationTimeout.NoTimeout,
                    actions: formatAuctionLinkNotifyActions(message),
                });

                return true;
            }

            return false;
        };

        const auctionListingManager = makeWsEntityManager(WsEntityType.Listing);
        const auctionDetailManager = makeWsEntityManager(WsEntityType.Detail);

        const { status, send } = useWebSocket(wsEndpoint, {
            autoReconnect: {
                ...webSocketConfig.autoReconnect,
                onFailed() {
                    logError('Error connecting WS:', { endpoint: wsEndpoint });
                },
            },

            heartbeat: {
                ...webSocketConfig.heartbeat,
                message: messageFormatter.ping(),
            },

            onConnected(ws) {
                isLoggedIn.value && rawToken.value && ws.send(messageFormatter.authorize(rawToken.value));
                auctionListingManager.sendObserveMessage({ subscribeOnly: true });
                auctionDetailManager.sendObserveMessage({ subscribeOnly: true });
            },

            onMessage(_, event) {
                const eventData = safeJsonParse<WsMessage>(event.data, 'WS /auctions message');
                if (!eventData) return;

                for (const handler of [
                    auctionListingManager.handleSnapshotMessage,
                    auctionDetailManager.handleSnapshotMessage,
                    handleUserOutBidMessage,
                    handleUserBailAuthorizedMessage,
                    handleUserMaxBidderRestoredMessage,
                    handleUserOffersUpdate,
                    handleAuctionWonMessage,
                    handleUserWinningBidMessage,
                    handleUserAutoBidMaxThresholdReachedMessage,
                ]) {
                    if (handler(eventData)) return;
                }
            },
        });

        watch(isLoggedIn, (value, oldValue) => {
            if (value === oldValue || status.value !== 'OPEN') return;
            send(value && rawToken.value ? messageFormatter.authorize(rawToken.value) : messageFormatter.unauthorize());
        });
    },
});
